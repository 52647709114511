<template>
    <section>
        <button ref="openModalButton" class="btn btn-outline-primary w-100 mobile" v-b-modal.blockDate> Ajouter une fermeture de date</button>
        <b-modal ref="myClosingModal" id="blockDate" hide-header hide-footer no-close-on-backdrop>

            <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-2"
                    v-model="repeat"
                    :aria-describedby="ariaDescribedby"
                    name="radio-sub-component"
                >
                    <b-form-radio :value="false">Sans répétition</b-form-radio>
                    <!-- <b-form-radio :value="true">Avec répétition</b-form-radio> -->
                </b-form-radio-group>
            </b-form-group>

            <!-- sans répétition -->
            <div class="card" v-if="!repeat">
                <div class="card-body">
                    <h3>Indiquez une fermeture</h3>
                    <p>Sélectionner la date de début et date fin, ensuite l'heure de début et l'heure de fin.</p> 
                    <p class="text-muted">S'il s'agit d'une même journée, sélectionner deux fois la même date</p>
                    <vc-date-picker class="w-100" v-model="date" mode="datetime" :model-config="modelConfig" is-range is24hr/>
                </div>
            </div>
            
            <!-- avec répétition -->
            <div class="card" v-else>
                <div class="card-body">
                    <b-form-group label="Description">
                        <b-form-textarea
                            id="description"
                            v-model="description"
                            placeholder="description"
                            rows="3"
                        >
                    </b-form-textarea>
                    </b-form-group>
                    <div class="d-flex justify-content-around mt-3">
                        <span class="d-flex w-100">
                            <b-form-group
                            class="w-100"
                            label-cols-sm="3"
                            label-cols-lg="3"
                            content-cols-sm
                            content-cols-lg="9"
                            label="De"
                            >
                            <b-form-timepicker 
                                  v-model="daily.start_closing_at"
                                  size="sm"
                                  id="example-input-1"
                                  name="example-input-1"
                                  minutes-step="5"
                                  no-close-button
                                  aria-describedby="input-1-live-feedback"
                                   locale="fr">
                            </b-form-timepicker>
                            <b-form-invalid-feedback id="input-1-live-feedback">
                                Ce champ est obligatoire.
                            </b-form-invalid-feedback>
                            </b-form-group>

                        </span>
                        <span class="d-flex w-100">
                            <b-form-group
                            class="w-100 text-center"
                            label-cols-sm="3"
                            label-cols-lg="3"
                            content-cols-sm
                            content-cols-lg="9"
                            label="à"
                            >
                                <b-form-timepicker 
                                    v-model="daily.end_closing_at"
                                    size="sm"
                                    id="example-input-1"
                                    name="example-input-1"
                                    minutes-step="5"
                                    no-close-button
                                    aria-describedby="input-1-live-feedback"
                                    locale="fr">
                                </b-form-timepicker>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                    Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                            </b-form-group>

                        </span>
                    </div>

                    <div class="d-flex">
                        <b-form-group
                        label-cols-sm="4"
                        label-cols-lg="4"
                        content-cols-sm
                        content-cols-lg="8"
                        label="Fréquence"
                        >
                            <select class="form-control" v-model="type">
                                <option value="daily">Tous les jours</option>
                                <option value="weekly">Toutes les semaines</option>
                            </select>
                        </b-form-group>
                    </div>

                    <div class="d-flex" v-if="type=='weekly'">
                        <b-form-group v-slot="{ ariaDescribedby }"
                        class="w-100"
                        label="Répéter le"
                        >
                            <b-form-checkbox-group
                                id="checkbox-group-2"
                                v-model="r_day"
                                :aria-describedby="ariaDescribedby"
                                name="flavour-2"
                            >
                                <b-form-checkbox value="1">L</b-form-checkbox>
                                <b-form-checkbox value="2">M</b-form-checkbox>
                                <b-form-checkbox value="3">M</b-form-checkbox>
                                <b-form-checkbox value="4">J</b-form-checkbox>
                                <b-form-checkbox value="5">V</b-form-checkbox>
                                <b-form-checkbox value="6">S</b-form-checkbox>
                                <b-form-checkbox value="0">D</b-form-checkbox>
                            </b-form-checkbox-group>
                        </b-form-group>
                    </div>
                </div>
            </div>
            <hr class="computer">
            <!-- validationécran large -->
            <div class="d-flex justify-content-end computer">
                <div class="btn-link btn mx-4" @click="closeModal()">Annuler</div>
                <button class="btn btn-primary" v-if="!repeat" @click="blockDate()">Confirmer</button>
                <button class="btn btn-primary" v-else @click="postClosingDatesNotification()">Confirmer</button>
            </div>
            <!-- valication écran mobile -->
            <div class="d-flex justify-content-around justify-content-around validation_section mobile">
                <div class="btn-link btn mx-4" @click="closeModal()">Annuler</div>
                <button class="btn btn-secondary w-100" v-if="!repeat" @click="blockDate()">Confirmer</button>
                <button class="btn btn-secondary w-100" v-else @click="postClosingDatesNotification()">Confirmer</button>
            </div>
            <div v-if="isload" class="jumping-dots-loader">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </b-modal>
    </section>
</template>
<script>
import axios from 'axios'
export default {
    name:"block-date",
    data(){
        return{
            date: {
                start:'',
                end:'',
                schedule:''
            },
             modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DDTHH:mm:ss', // Uses 'iso' if missing
            },
            isload: false,
            unavailableschedule:[],
            // si sans répétition
            repeat:false,
            type:"daily",
            description:"",
            // si avec répétition journalière
            daily:{
                start_closing_at:"12:00",
                end_closing_at:"13:00",
            },
            // si avec répétition hebdomadaire
            r_day:[],
            company: ""
        }
    },
    computed:{
    },
    methods:{
        openModal() {
            this.$refs.myClosingModal.show();
        },
        openModalWithParameter(date) {
            this.$refs['myClosingModal'].show()
            this.date = date
        },
        closeModal() {
            this.$bvModal.hide("blockDate")
            this.$emit('updateCalendar')
        },
        // si date sans répétition
        async blockDate(){
            this.isload=true
            const bool = await this.getschedule()
            this.loadershow = true
            if (bool) {
                let text = ""
                this.unavailableschedule.forEach(element => {
                    text+=" "+element.startDate+" "+element.endDate
                });
                console.log(text);
                this.loadershow =false
                this.$swal({
                    title: 'Une réservation a été faite dans cette fermeture de date, Merci de bien vérifier l\'agenda',
                    showCancelButton: true,
                    confirmButtonColor: '#0b0b0b',
                    cancelButtonColor: '#fff',
                    // cancelButtonText: 'Annuler',
                    confirmButtonText:'Actualiser'
                })
                .then(() => location.reload())
                return
            }
            if(!this.date.start || !this.date.end){
                alert('vous devez sélectionner un horaire')
                return
            }
            this.isload= true
            axios.post('closing/dates',{
                start_closing_at:this.date.start,
                end_closing_at:this.date.end,
                repeating:false,
                companies:localStorage.getItem('company_client_ulid')
            })
            .then(resblockDate => {
                console.log('post block date',resblockDate)
                this.$bvModal.hide('blockDate')
                this.$emit("updateCalendar")
                })
            .catch(err => console.log(err))
        },

        // si avec répétition
        closingDatesBody(){
            // si fermeture hebdomadaire
            let closing_date_repeating = []

            if(this.type=="weekly" && this.description=="horaire") {
                // création des dates de fermeture à partir des dates d'ouverture sélectionnées
                for (let day of this.weekly) {
                    // si la journée entière est fermée
                    if(day.status !== "open") {
                        closing_date_repeating.push({
                            r_day:day.r_day,
                            r_start_hours:"00:00",
                            r_end_hours:"23:59"
                        })
                    }
                    // définition des heures de fermeture du jour
                    else if(day.status == "open" && day.opening_time && day.closing_time) {
                        closing_date_repeating.push(
                            {
                                r_day:day.r_day,
                                r_start_hours:"00:00",
                                r_end_hours:day.opening_time
                            },
                            {
                                r_day:day.r_day,
                                r_start_hours:day.closing_time,
                                r_end_hours:"23:59"
                            })
                    }
                    // ## A METTRE PLUS TARD : STOPPER LA PROPAGATION SI IL Y A UN INPUT TIME VIDE
                    else if(!day.opening_time || !day.closing_time) {
                        alert ('Veuillez mettre les heures sur les journées ouvertes')
                        return
                    }
                }
            }
            // si il est ajouté depuis la fermeture individuelle
            else if(this.type=="weekly" && this.description!=="horaire"){
                for(let day of this.r_day){
                    closing_date_repeating.push({
                        r_day:Number(day),
                        r_start_hours:this.daily.start_closing_at,
                        r_end_hours:this.daily.end_closing_at
                    })
                }
            }
            // si fermeture journalière
            else if(this.type=="daily"){
                closing_date_repeating.push({
                    r_start_hours:this.daily.start_closing_at,
                    r_end_hours:this.daily.end_closing_at
                })
            }
            let body = {
                "start_closing_at":  "2022-06-05T06:55:46+00:00",
                "end_closing_at": "2023-09-05T06:55:46+00:00",
                "description":this.description,
                "repeating": true,
                "type": this.type,
                "companies":this.company,
                "closing_date_repeating": closing_date_repeating
            };
            return body
        },
        postClosingDatesNotification(){
            this.$swal({
                title: "Êtes-vous sûr?",
                text: "Voulez-vous valider cette fermeture?",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui, valider",
                cancelButtonText: "Annuler",
            }).then(async (result) => {
                if (result.value) {
                await this.postClosingDates()
                this.$swal("Ajouté!", "Les horaires de fermeture ont été ajoutés", "success");
                this.$bvModal.hide('blockDate')
                this.$emit("updateCalendar")
                }
            });
        },
        postClosingDates(){
            this.requestClosingDates("post","closing/dates",this.closingDatesBody())
        },
        requestClosingDates(method,url,body){
            this.ploader= true
              axios[method](url, body,
                {
                  headers: {
                    "X-AUTH-TOKEN": localStorage.getItem("token")
                  },
                }
              )
              .then(
                ()=>{
                  this.ploader= false
                }
              )
              .catch(
                err => {
                  this.ploader= false
                  console.error(method,err)
                }
              )
        },
        async getschedule(){
            this.loadershow =true
            this.message ="Verification des dates"
             await axios.get('schedules/appointments')
            .then(
                resschedule => {
                    console.log({resschedule})
                    this.schedule =resschedule.data.result
                }
            )
            .catch(
                errpurchases=> {
                    errpurchases
                }
            )

            if(this.schedule){
                    this.schedule.forEach(scheduleobject => {
                        if (
                            this.dateToNumber(this.date.start) >= this.dateToNumber(scheduleobject.datestart)  &&  
                            this.dateToNumber(this.date.start) <= this.dateToNumber(scheduleobject.dateend)  ||
                            this.dateToNumber(this.date.end)  >= this.dateToNumber(scheduleobject.datestart)  &&
                            this.dateToNumber(this.date.end) <= this.dateToNumber(scheduleobject.dateend)
                            ) 
                        {
                            this.unavailableschedule.push(this.date)
                        }
                    });
            }
            this.message="Traitement des données en cours"
            if (this.unavailableschedule.length > 0 ) {
                return true
            }
            if(this.unavailableschedule.length == 0){
                return false
            }
        },
        dateToNumber(stringDate){
            let date = stringDate.substr(0,16)
            date = date.replace('-','')
            date = date.replace('T','')
            date = date.replace(':', '')
            date = date.replace('-','')
            date = date.replace(' ','')
            date = Number(date)
            return date
        },
        dateString(mydate){
            var stringdate;
            if (!mydate) {
                mydate = new Date();    
            }
            stringdate =  mydate.getFullYear() + "-"
                + this.digit((mydate.getMonth()+1))  + "-" 
                + this.digit(mydate.getDate()) + " "

                + this.digit(mydate.getHours()) + ":"  
                + this.digit(mydate.getMinutes()) + ":" 
                + this.digit(mydate.getSeconds())

            return stringdate
        },
    },
    mounted(){
    }
}
</script>